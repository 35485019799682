<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="video">
          <video muted autoplay="autoplay" controls width="800">
            <source
              src="https://file.smallpay.com/group1/M00/39/46/wKis0GO2Jj2AUlc-AfprSti0Uqc758.mp4"
              type="video/mp4"
            />
            抱歉，您的浏览器不支持内嵌视频。
          </video>
        </div>
        <p>
          长春凯达发展有限公司是长春国际汽车城所属大型国有独资企业，成立于2005年12月1日，注册资本124713.335万元，主要负责开发区融资业务，承接国家及省市金融政策，承接政府投资项目的实施及资金管理，承担政府城市基础设施建设职能，承接国家专项债券项目的实施，开展项目投资、项目建设等相关业务。
        </p>
        <p>
          在落实“支持一汽建设世界一流企业、建设世界一流汽车城”战略，推动长春国际汽车城稳步向万亿级产值迈进的过程中，凯达公司作为重要载体，肩负着推进长春国际汽车城建设的重要历史使命，承载着引领区域振兴发展的殷切期望。
        </p>
        <p>
          秉承强烈的使命意识、责任意识和主人翁意识，凯达公司积极落实省市战略，勇于担当，践行“为使命而战、为荣誉而战、为梦想而战”。通过加强前瞻性思考、全局性谋划、战略性布局和整体性推进，“创凯达模式、立凯达标准、树凯达品质、展凯达形象”，为长春国际汽车城未来发展提供新供给、满足新需求、开辟新通道、培育新动能释放巨大潜力。
        </p>
        <p>
          对标长春国际汽车城“双一流”、“万亿级”战略目标，凯达公司将以“一流的理念、一流的人才、一流的团队、一流的管理、一流的平台、一流的品质”六个一流建设、实现企业“双千亿”为发展战略，紧紧围绕“融、投、建、管、退”五大职能，不断发展壮大企业规模，提高服务经济社会发展的能力，逐步实现国有资本投资运营市场化，积极建立具备持续投资融资发展能力的综合性、现代化、市场化企业集团。
        </p>
        <p>
          目前，我公司拥有一支年轻化、高素质、精于专、胜于能的职业化团队，平均年龄32岁，其中“双一流”高校毕业生占比38%，本科学历占比74%，硕士学历占比7%，致力打造一支素质优良、结构合理、效能优先、具有市场开拓精神和管理创新能力的高水平人才队伍，为不断壮大国有经济提供强有力的人才保障和智力支持。
        </p>
        <p>
          我公司目前承接了55个重点项目，其中新建项目2个（总投资约34.4亿元）、续建项目3个（总投资约13.4亿元）、拟启动项目5个(总投资约123.5亿元)、储备项目16个、规划项目29个。在长春国际汽车城、长春国际化都市圈建设中充分发挥引领、示范、带动和辐射作用，形成新的集聚优势和放大效应。
        </p>
        <p>
          全体凯达人将坚持稳中求进总基调，立足新发展阶段、贯彻新发展理念、构建新发展格局，以推动高质量发展为主题，以“建设世界一流汽车城先行者”为使命，以“融、投、建、管、退”为主线，以改革创新为根本动力，积极落实省市发展战略，通过降低负债、盘活资产、重组整合、优化配置等途径，完善企业管理，提升国有资本质量，加快国有经济布局结构优化，促进国有资产保值增值，推动国有资本做优做大做强，充分发挥国有资本管理平台的重要作用，积极促进国有资本资源高效配置，全面、精准承接一汽“六个回归”政策，为实现长春国际汽车城“双一流”、“万亿级”的战略目标而不懈奋斗。
        </p>
        <p class="align-right">二〇二一年七月</p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner1.jpg"),
    };
  },
  components: { Crumb, Banner },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .title {
      text-align: center;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;
      h1 {
        font-size: 28px;
        line-height: 60px;
        font-weight: normal;
        text-align: center;
      }
      p {
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        span {
          margin: 0 20px;
        }
      }
    }
    .content {
      .video {
        text-align: center;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 1em;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
